"use client";

import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import { useEffect, useRef } from "react";

export const useRefreshSession = () => {
  const { data: session, update } = useSession();
  const router = useRouter();

  useEffect(() => {
    const visibilityHandler = async () => {
      if (document.visibilityState === "visible") {
        const response = await update();

        console.debug("Session refreshed", response);
        if (response?.user.plan !== session?.user.plan) {
          router.refresh();
        }
      }
    };

    window.addEventListener("visibilitychange", visibilityHandler, false);

    return () =>
      window.removeEventListener("visibilitychange", visibilityHandler, false);
  }, [update, session, router]);

  // also update only once on mount
  const updatedOnce = useRef(false);

  useEffect(() => {
    if (!updatedOnce.current) {
      updatedOnce.current = true;
      update().then((response) => {
        console.debug("Session refreshed", response);
      }, console.error);
    }
  }, [update]);

  return session;
};

export const RefreshSession = () => {
  useRefreshSession();

  return null;
};
